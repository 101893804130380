<template>
  <v-dialog v-model="show" max-width="40%" persistent>
    <template v-slot:activator="{ on, attrs }" v-if="assignedRole.data.permissions.roles">
      <v-btn v-bind="attrs" v-on="on" elevation="0" color="primary">
        <v-icon left> mdi-plus </v-icon>
        Add New Role
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-center"> Add New Role </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
      </v-progress-linear>
      <v-card-text class="px-0">
        <v-form ref="addNewRoleForm" lazy-validation class="px-6">
          <v-row no-gutters>
            <v-col cols="6">
              <v-text-field v-model="role.name" label="Role Name" :rules="rules.nameRules" required>
              </v-text-field>
              <v-textarea outlined label="Description" @keyup.enter="addNewRole" v-model="role.description">
              </v-textarea>
            </v-col>
            <v-col cols="6" align-self="center">
              <v-card-title class="text-h6 black--text justify-center"> Permissions </v-card-title>
              <span class="px-5 black--text font-weight-bold">Group Permissions</span>
              <v-row no-gutters align="center" justify="center" class="pb-5">
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.orgGrp.read" label="Read" color="primary" hide-details></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.orgGrp.write" label="Write" color="primary" hide-details></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.orgGrp.delete" label="Delete" color="red" hide-details></v-checkbox>
                </v-col>
              </v-row>
              <span class="px-5 black--text font-weight-bold">Kiosk Permissions</span>
              <v-row no-gutters align="center" justify="center" class="pb-10">
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.kiosk.read" label="Read" color="primary" hide-details></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.kiosk.write" label="Write" color="primary" hide-details></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="role.permissions.kiosk.delete" label="Delete" color="red" hide-details></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col align="end">
              <v-btn @click="show = false" color="error" dark large outlined width="150" class="mx-3">
                <v-icon left>mdi-cancel</v-icon>
                Cancel
              </v-btn>
              <v-btn @keyup.enter="addNewRole" @click="addNewRole" color="primary" dark large width="150">
                <v-icon left>mdi-plus</v-icon>
                Add Role
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "addNewRole",
  data() {
    return {
      show: null,
      loading: false,
      role: {
        name: "",
        description: "",
        permissions: {
          kiosk: {
            read: true,
            write: false,
            delete: false,
            add: false
          },
          orgGrp: {
            read: true,
            write: false,
            delete: false,
            add: false,
          },
          user: {
            read: true,
            write: false,
            delete: false,
            add: false
          },
          config: false,
          roles: false
        }
      },
      rules: {
        nameRules: [(v) => !!v || "Role name is required"],
        descriptionRules: [(v) => !!v || "Description is required"],
      },
    };
  },
  computed:{
    ...mapGetters(['assignedRole'])
  },
  methods: {
    addNewRole() {
      if (this.$refs.addNewRoleForm.validate()) {
        this.loading = true;
        console.log('New Added Role', this.role)
        this.$store.dispatch("addNewRole", this.role).then(
          (response) => {

            this.loading = false;
            this.show = false;
            this.$store.dispatch("showSnackBar", {
              text: response,
              color: "success",
            });
            this.$refs.addNewRoleForm.reset();
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );
      }
    },
  },
};
</script>